<template>
  <v-menu v-if="user" width="300px">
      <template #activator="{ props }">
        <v-btn color="transparent" data-cy-account-menu-toggle icon variant="flat" v-bind="props">
          <AppUserAvatar size="small" />
        </v-btn>
      </template>
      <v-card width="100%">
          <v-list>
              <v-list-item data-cy-account-name lines="two" :title="fullName" :subtitle="user!.email">
                <template #prepend>
                  <AppUserAvatar size="small" />
                </template>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item data-cy-settings-link :title="t('general.settings')" :to="{name: 'settings'}" prepend-icon="mdi-cog-outline" value="settings"></v-list-item>
              <SuperAdminOnly>
                <v-list-item data-cy-admin-settings-link :to="{name: 'administration'}" title="Administration" prepend-icon="mdi-security" />
              </SuperAdminOnly>
              <v-divider></v-divider>
              <v-list-item @click="signOut()" :title="t('general.signOut')" prepend-icon="mdi-logout-variant" value="signout"></v-list-item>
          </v-list>
      </v-card>
  </v-menu>
  <AppLogin v-else />
</template>

<script setup lang="ts">
import {useAuthStore} from "@/modules/authentication/stores/auth";
import {useCurrentUserStore} from "@/stores/current-user";
import {storeToRefs} from "pinia";
import AppUserAvatar from "@/components/AppUserAvatar.vue";
import AppLogin from "@/components/AppLogin.vue";
import {useI18n} from "vue-i18n";
import {useIsAuthorized} from "@/modules/authorization/composables/is-authorized";
import {RoleType} from "@/modules/authorization/utils/role-type.enum";
import SuperAdminOnly from "@/modules/authorization/components/SuperAdminOnly.vue";

const currentUserStore = useCurrentUserStore();
const {fullName, user} = storeToRefs(currentUserStore)
const {t} = useI18n();
const {signOut} = useAuthStore();
</script>

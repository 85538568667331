import {createRouter, createWebHistory, RouterView} from 'vue-router';
import settingsRoutes from '@/modules/settings/router';
import tenantsRoutes from '@/modules/tenants/router';
import accountsRoutes from '@/modules/accounts/router';
import {useCurrentUserStore} from "@/stores/current-user";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: RouterView,
            beforeEnter: async () => {
                const {getCurrentUser} = useCurrentUserStore();
                await getCurrentUser();
            },
            meta: {
                auth: true,
                breadcrumb: 'Home'
            },
            redirect: {
                name: 'dashboard'
            },
            children: [
                {
                    path: 'administration',
                    name: 'administration',
                    component: () => import('@/views/AdministrationView.vue'),
                    meta: {
                        breadcrumb: 'Administration'
                    },
                    redirect: {
                        name: 'administration.tenants'
                    },
                    children: [
                        ...tenantsRoutes,
                        ...accountsRoutes,
                    ]
                },
                ...settingsRoutes,
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () => import('@/views/DashboardView.vue')
                }
            ]
        },
        {
            path: '/endsession',
            redirect: {path: '/'}
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: {path: '/'},
        }
    ]
})


export default router

import type {RouteRecordRaw} from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: 'settings',
        name: 'settings',
        meta: {
            breadcrumb: {
                key: 'settings.label',
            }
        },
        component: () => import('@/modules/settings/views/SettingsIndex.vue'),
    }
];

export default routes;

import type {RouteRecordRaw} from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: 'tenants',
        meta: {
            breadcrumb: {
                key: 'organization.entity',
                plural: 2
            },
        },
        children: [
            {
                path: '',
                name: 'administration.tenants',
                component: () => import("@/modules/tenants/views/TenantsIndex.vue"),
                children: [
                    {
                        path: 'create',
                        name: 'tenant-create',
                        component: () => import("@/modules/tenants/views/TenantsCreate.vue"),
                    },
                ]
            },
        ]
    },
];


export default routes;

import {CurrentUserClient, ManagementClient, TenantsClient, UsersClient, AuditClient as GatewayAuditClient} from "@/api/GatewayAPIClient";
import {UsersClient as TenantUsersClient} from "@/api/TenantAPIClient";
import {useAuthStore} from "@/modules/authentication/stores/auth";

const ApiFetchProxy = {
    fetch: async (input: RequestInfo, init: RequestInit) => {

        const {accessToken} = useAuthStore();


        init.headers = {
            ...init.headers,
            Authorization: `Bearer ${accessToken}`
        }


        return fetch(input, init);
    }
}

export const useCurrentUserClient = () => new CurrentUserClient(window.hsp.GATEWAY_API_BASE_URL, ApiFetchProxy);
export const useTenantsClient = () => new TenantsClient(window.hsp.GATEWAY_API_BASE_URL, ApiFetchProxy);
export const useUsersClient = () => new UsersClient(window.hsp.GATEWAY_API_BASE_URL, ApiFetchProxy);
export const useGatewayAuditClient = () => new GatewayAuditClient(window.hsp.GATEWAY_API_BASE_URL, ApiFetchProxy);
export const useManagementClient = () => new ManagementClient(window.hsp.GATEWAY_API_BASE_URL, ApiFetchProxy);

export const useTenantApi = (baseUrl: string) => {

    if (baseUrl.endsWith('/')) {
        baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }

    const usersClient = new TenantUsersClient(baseUrl, ApiFetchProxy);

    return {
        usersClient
    }

}




<script setup lang="ts">

import AppAccountMenu from "@/components/AppAccountMenu.vue";
import AppThemeToggle from "@/components/AppThemeToggle.vue";
import AppLogo from "@/components/AppLogo.vue";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import {onUnmounted, ref} from "vue";
import {useMutationObserver} from "@vueuse/core";
import { useBreadcrumbs } from '@/composables/breadcrumbs'

const {breadcrumbs} = useBreadcrumbs();
const appBarPrependTeleportTarget = ref<HTMLElement>();
const hasTeleportedContent = ref(false);
const obsCb = () => {
  hasTeleportedContent.value =  (appBarPrependTeleportTarget.value?.children.length ?? 0) > 0 || false;
}
const {stop} = useMutationObserver(
    appBarPrependTeleportTarget,
    obsCb,
    {childList: true}
)

onUnmounted(() => {
  stop();
})

</script>

<template>
  <div>
    <v-app-bar class="hsp-app-bar border-b" color="surface" elevation="0">
      <template #prepend>
        <div ref="appBarPrependTeleportTarget" id="app-bar-prepend" />
        <v-icon class="ml-4" v-if="!hasTeleportedContent" :icon="AppLogo" />
      </template>

      <template #title>
        <div class="d-flex flex-column">
          <v-app-bar-title>
            <v-icon class="mr-1" size="small" v-if="hasTeleportedContent" :icon="AppLogo" />
            Health Study Project
          </v-app-bar-title>
          <AppBreadcrumbs v-if="breadcrumbs.length > 1" />
        </div>
      </template>

      <template #append>
        <AppThemeToggle />
        <AppAccountMenu />
      </template>
    </v-app-bar>
    <slot />
  </div>


</template>

<style lang="scss">
.hsp-app-bar {
  .v-toolbar-title {
    font-size: 1rem !important;
  }
}



</style>

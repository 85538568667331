import type {RouteRecordRaw} from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: 'accounts',
        meta: {
            breadcrumb: {
                key: 'account.entity',
                plural: 2
            },
        },
        children: [
            {
                path: '',
                name: 'administration-accounts',
                component: () => import('@/modules/accounts/views/AccountsIndex.vue'),
            }
        ]

    }
]


export default routes;
